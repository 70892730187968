/**
 * 商品管理API
 */

import request from '@/utils/request'
import { api } from '@/../ui-domain'

/**
 * 搜索图片库类型列表
 * @param params
 * @returns {Promise<any>}
 */
export function listCategory(params){
	return request({
		url: `${api.admin}/admin/systems/picture/listCategory`,
		method: 'get',
		params
	})
}

/**
 * 新增图片库类型
 * @param params
 * @returns {Promise<any>}
 */
export function addCategory(params) {
	return request({
		url: `${api.admin}/admin/systems/picture/addCategory`,
		headers:{"Content-Type":"application/json"},
		method: 'post',
		data: params
	})
}

/**
 * 删除图片库类型
 * @param params
 * @returns {Promise<any>}
 */
export function deleteCategory(id) {
	return request({
		url: `${api.admin}/admin/systems/picture/deleteCategory/${id}`,
		method: 'DELETE'
	})
}

/**
 * 修改图片库类型
 * @param params
 * @returns {Promise<any>}
 */
export function updateCategory(params){
	console.log(params);
	return request({
		url: `${api.admin}/admin/systems/picture/updateCategory`,
		method: 'post',
		headers:{"Content-Type":"application/json"},
		data: params
	})
}

/**
 * 搜索图片库图片列表
 * @param params
 * @returns {Promise<any>}
 */
export function listPicture(params){
	return request({
		url: `${api.admin}/admin/systems/picture/listPicture`,
		method: 'get',
		params
	})
}

/**
 * 新增图片库图片
 * @param params
 * @returns {Promise<any>}
 */
export function addPicture(params) {
	return request({
		url: `${api.admin}/admin/systems/picture/addPicture`,
		headers:{"Content-Type":"application/json"},
		method: 'post',
		data: params
	})
}

/**
 * 删除图片库图片
 * @param params
 * @returns {Promise<any>}
 */
export function deletePicture(id) {
	return request({
		url: `${api.admin}/admin/systems/picture/deletePicture/${id}`,
		method: 'DELETE'
	})
}

