<template>
  <div class="setting-image-list">
    <en-table-layout :table-data="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div class="col-auto toolbar-title"></div>

        <el-form-item label="图片库类型">
          <el-select
            style="width: 120px"
            size="small"
            placeholder="全部"
            v-model="params.category_id"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.category_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="添加时间">
          <el-date-picker
            style="width: 220px"
            v-model="times"
            type="daterange"
            :editable="false"
            unlink-panels
            size="small"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :picker-options="{
              disabledDate(time) {
                return time.getTime() > Date.now();
              },
              shortcuts: MixinPickerShortcuts,
            }"
          ></el-date-picker>
        </el-form-item>

        <el-button type="primary" size="small" @click="GET_listPicture">
          搜索
        </el-button>

        <div class="col"></div>

        <el-button
          type="primary"
          size="small"
          title="建议为16:9的尺寸，大小不超5MB"
          @click="openDialog()"
        >
          添加图片
        </el-button>
      </template>
      <template slot="table-columns">
        <el-table-column label="编号" prop="id" width="80"></el-table-column>
        <el-table-column label="图片" width="110">
          <template slot-scope="{row}">
            <el-popover
              placement="right"
              trigger="hover"
            >
              <img :src="row.img_url" alt="" style="width: 300px">
              <img :src="row.img_url" width="80" alt="" slot="reference"/>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="添加时间"
          prop="created_time"
          :formatter="MixinUnixToDate"
        ></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="del(scope.row)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
    <el-dialog
      title="图片添加"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="
        () => {
          btnClick = true;
          dialogVisible = false;
          addParams.img_url = '';
        }
      "
    >
      <el-upload
        class="site-logo"
        :action="MixinUploadApi"
        list-type="picture-card"
        :show-file-list="false"
        :on-success="
          (res) => {
            btnClick = false;
            imgloading = false;
            addParams.img_url = res.url;
          }
        "
        v-loading.fullscreen.lock="imgloading"
        :on-progress="imgsProgress"
        :on-preview="imgsPreview"
        :on-remove="imgsRemove"
        :multiple="false"
        :on-error="handleError"
      >
        <img
          v-if="addParams.img_url"
          :src="addParams.img_url"
          class="site-logo-img"
          alt=""
        />
        <i v-else class="el-icon-plus logo-uploader-icon"></i>
      </el-upload>
      <div class="conditions" style="margin-top: 20px">
        <span>图片库类型：</span>
        <el-select
          style="width: 120px"
          size="small"
          placeholder="全部"
          v-model="addParams.img_category_id"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.category_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div class="tip" style="padding: 8px 0; color: #999; font-size: 13px; margin-bottom: -36px; line-height: 1.5;">
          提示：轮播图建议尺寸750*400，一行五列小图建议尺寸300*300，其他位置图片尺寸不限制。
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            addParams.img_url = '';
            btnClick = true;
          "
          size="small"
        >取 消
        </el-button>
        <el-button
          type="primary"
          :disabled="btnClick"
          @click="submitUpload()"
          size="small"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_imgSetting from "@/api/imgSetting";
import {Foundation} from "@/../ui-utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: "imageList",
  components: {EnTableLayout},
  data() {
    return {
      loading: false,
      btnClick: true,
      imgloading: false,
      dialogVisible: false,
      times: "",
      addParams: {
        img_url: "",
        sn: "1",
        img_category_id: "",
      },
      paramsType: {
        page_no: 1,
        page_size: 2000,
      },
      params: {
        sn: "1",
        page_no: 1,
        page_size: 20,
        start_time: "",
        end_time: "",
        category_id: "",
      },
      tableData: {data: []},
      typeOptions: [],
    };
  },
  mounted() {
    this.GET_listCategory();
    this.GET_listPicture();
  },
  methods: {
    POST_deletePicture(id) {
      API_imgSetting.deletePicture(id).then((res) => {
        this.GET_listPicture();
        this.category_name = "";
        this.start_time = "";
        this.end_time = "";
        this.times = "";
      });
    },
    POST_addPicture() {
      API_imgSetting.addPicture(this.addParams).then((res) => {
        this.GET_listPicture();
        this.category_name = "";
        this.start_time = "";
        this.end_time = "";
        this.times = "";
        this.addParams.img_url = "";
        this.addParams.img_category_id = "";
        this.btnClick = true;
      });
    },
    GET_listCategory() {
      API_imgSetting.listCategory(this.paramsType).then((res) => {
        this.typeOptions = res.data;
      });
    },
    GET_listPicture() {
      if (!!this.times) {
        this.params.start_time = this.times[0];
        this.params.end_time = this.times[1] + 1000 * 60 * 60 * 24;
      } else {
        this.params.start_time = '';
        this.params.end_time = '';
      }
      API_imgSetting.listPicture(this.params).then((res) => {
        res.data.forEach((item, index) => {
          res.data[index].created_time = item.created_time / 1000;
        });
        this.tableData = res;
        console.log(this.tableData);
      });
    },
    del(row) {
      this.$confirm(
        "确定要删除该图片吗？删除后正在使用该图片的企业站点就不能使用该图片了",
        "删除操作",
        {type: "warning"}
      ).then(() => {
        this.POST_deletePicture(row.id);
      });
    },
    // 图片上传失败的回调
    handleError(err) {
      let error = JSON.parse(err.message).message;
      this.$message.error(error);
      this.imgloading = false;
    },
    handlePageSizeChange(val) {
      this.params.page_size = val;
      this.GET_listPicture();
    },
    handlePageCurrentChange(val) {
      this.params.page_no = val;
      this.GET_listPicture();
    },
    openDialog() {
      this.dialogVisible = true;
    },
    submitUpload() {
      if (
        this.addParams.img_url != "" &&
        this.addParams.img_category_id != ""
      ) {
        this.POST_addPicture();
        this.dialogVisible = false;
      } else {
        this.$message.error("请选择图片库类型");
      }
    },
    // 图片上传时的钩子
    imgsProgress() {
      this.btnClick = true;
      this.imgloading = true;
    },
    imgsRemove(file) {
      console.log(file);
    },
    imgsPreview(file, fileList) {
      console.log(file);
      console.log(fileList);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep div.toolbar {
  padding: 20px;

  .conditions {
    display: inline-block !important;
    margin-right: 20px;
  }
}

.goods-image {
  width: 106px;
  height: 60px;
}

.site-logo-img {
  width: 100%;
  height: 100%;
  display: block;
}

::v-deep .site-logo .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: #409eff;
  }
}
</style>
